import NaviBlock from "@/components/Navigation/NaviBlock";
import CustomerService from "@/services/CustomerService";


export default {
    components: {
        NaviBlock
    },
    data() {
        return {
            customers: [],
            filterViews: {
                actawait: this.$t("projects.filter_active_and_awaiting"),
                act: this.$t("projects.filter_active"),
                awaiting: this.$t("projects.filter_awaiting"),
                fin: this.$t("projects.filter_finished"),
                ccl: this.$t("projects.filter_cancelled"),
                all: this.$t("projects.filter_all"),
            },
        }
    },
    methods: {
        /**
         * Get customers
         */
        async fetchCustomers() {
            try {
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}
        },
    }

}
