<script>
export default {
    name: "NaviBlock",
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        disableRouteMatching: {
            type: Boolean,
            default: false,
        },
        forceNavigation: {
            type: Boolean,
            default: false,
            description: "Force navigation even if active",
        },
        navigate: Object,
        title: {
            type: String,
        },
    },
    data() {
        return {
            is_active: false,
        };
    },
    watch: {
        $route(to, from) {
            this.matchRouteActive();
        },
    },
    created() {
        this.is_active = this.active;
        if (!this.disableRouteMatching) {
            this.matchRouteActive();
        }
    },
    methods: {
        matchRouteActive() {
            if (this.navigate) {
                if (this.$route.name == this.navigate.name) {
                    this.is_active = true;
                } else {
                    this.is_active = false;
                }
            }
        },
        doNavigation() {
            if (this.is_active == false || this.forceNavigation == true) {
                this.$router.push(this.navigate);
            }
        },
    },
};
</script>

<template>
    <div
        class="navi-block"
        :class="{
            active: is_active,
            'mark-clickable':
                (is_active == false || forceNavigation == true) && navigate,
        }"
        @click="doNavigation"
    >
        {{ title }}
        <div class="navi-block__shadow" v-if="is_active"></div>
    </div>
</template>

<style lang="scss">
.navi-block {
    font-size: 15px;
    font-weight: 400;
    position: relative;
    white-space: nowrap;
    color: $gray-600;
    padding-top:2px;    

    &.active {
        color: $primary;
    }

    &__shadow {
        position: absolute;
        width: 100%;
        height: 5px;
        background: $primary;
        top: 44px;
        border: 1px solid $primary;
    }
}
</style>