import { render, staticRenderFns } from "./VProjectsPerformance.vue?vue&type=template&id=1f600c5a&"
import script from "./VProjectsPerformance.vue?vue&type=script&lang=js&"
export * from "./VProjectsPerformance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports